import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FaArrowAltCircleLeft } from "react-icons/fa";

//actions
import { editAsync, deleteAync } from "../../redux/customers/customers-actions";

//chakra ui
import {
  Button,
  Switch,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";

//components
import Input from "../../components/FormInput";
import PageTemplate from "../../components/PageTemplate";
import Select from "../../components/Select";

const EditCustomer = () => {
  const { id } = useParams();
  const history = useHistory();

  const customers = useSelector((state) => state.customers.customers);
  const editing = useSelector((state) => state.customers.editing);
  const deleting = useSelector((state) => state.customers.deleting);

  const customer = customers.filter((customer) => customer.id === id)[0];

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const dispatch = useDispatch();
  const toast = useToast();

  const [details, setDetails] = React.useState({
    name: customer.name,
    email: customer.email,
    phoneNum: customer.phoneNum,
    country: customer.country,
    state: customer.state,
    city: customer.city,
    allowTransfers: customer.allowTransfers,
    active: customer.active,
  });

  const onChange = (e) => {
    const { name, value, checked } = e.target;

    if (
      checked === true ||
      (checked === false && value === "true") ||
      value === "false"
    ) {
      return setDetails({
        ...details,
        [name]: checked,
      });
    }

    setDetails({
      ...details,
      [name]: value.trim(),
    });
  };

  const editCustomerInfo = async () => {
    try {
      await dispatch(
        editAsync({
          id: customer.id,
          ...details,
        })
      );

      toast({
        title: "Account edited.",
        description: "Account was edited successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/manage-customers");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const deleteCustomer = async () => {
    try {
      await dispatch(deleteAync(customer.id));
      toast({
        title: "Account deleted.",
        description: "Account was deleted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/manage-customers");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const { name, phoneNum, country, state, city } = details;

  return (
    <PageTemplate
      heading="Customer Information"
      subHeading="edit customer information"
      icon={<FiEdit />}
      link={
        <Link to="/admin/manage-customers">
          <FaArrowAltCircleLeft className="w-8 h-8 text-primary" />
        </Link>
      }
    >
      <form className="grid md:grid-cols-2 gap-y-4 md:gap-4">
        <Input
          label="customer's name"
          required
          onChange={onChange}
          name="name"
          value={details.name}
        />

        <Input
          label="customer's email"
          name="email"
          disabled={true}
          value={details.email}
        />

        <Input
          label="customer's phone number"
          type="number"
          onChange={onChange}
          name="phoneNum"
          value={details.phoneNum}
        />

        <Select onChange={onChange} name="country" />

        <Input
          label="enter state"
          onChange={onChange}
          name="state"
          value={details.state}
        />

        <Input
          label="enter city"
          onChange={onChange}
          name="city"
          value={details.city}
        />

        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0" className="text-gray-500">
            Allow Transfers
          </FormLabel>
          <Switch
            size="lg"
            name="allowTransfers"
            colorScheme="primary"
            onChange={onChange}
            defaultChecked={details.allowTransfers}
            value={details.allowTransfers}
          />
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0" className="text-gray-500">
            Account Status
          </FormLabel>
          <Switch
            size="lg"
            name="active"
            colorScheme="primary"
            onChange={onChange}
            defaultChecked={details.active}
            value={details.active}
          />
        </FormControl>
      </form>

      <div className="flex justify-end space-x-3">
        <Button
          onClick={() => setIsOpen(true)}
          className="text-white bg-red-500"
        >
          Delete Customer
        </Button>
        <Button
          isLoading={editing}
          className="text-white bg-primary"
          onClick={editCustomerInfo}
          isDisabled={
            name === "" ||
            phoneNum === "" ||
            country === "" ||
            state === "" ||
            city === ""
          }
        >
          Edit Account
        </Button>
      </div>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={deleteCustomer}
                ml={3}
                isLoading={deleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PageTemplate>
  );
};

export default EditCustomer;
