import React from "react";
import { FiRepeat } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//actions
import { createTransAsync } from "../../redux/transactions/trans-actions";

//chakra ui
import { Button, useToast, Select } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import Input from "../../components/FormInput";
import PageTemplate from "../../components/PageTemplate";

const BankTransfers = () => {
  const [transInfo, setTransInfo] = React.useState({
    id: "",
    senderAccNum: "",
    receipAccNum: "",
    routingNum: "",
    amount: "",
    date: "",
    description: "",
    status: "",
  });

  const dispatch = useDispatch();
  const creating = useSelector((state) => state.transactions.creating);
  const toast = useToast();
  const history = useHistory();

  const onChange = (e) => {
    const { name, value } = e.target;

    setTransInfo({
      ...transInfo,
      [name]: value.trim(),
    });
  };

  const createTransaction = async () => {
    try {
      await dispatch(createTransAsync(transInfo));

      toast({
        title: "Transaction created.",
        description: "Transaction created successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      history.push("/admin/all-transactions");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setTransInfo({
        id: "",
        senderAccNum: "",
        receipAccNum: "",
        routingNum: "",
        amount: "",
        date: "",
        description: "",
        status: "",
      });
    }
  };

  return (
    <Layout>
      <PageTemplate
        heading="create transaction"
        subHeading="create new transaction"
        icon={<FiRepeat />}
      >
        <p className="text-sm">
          * A Particular Customer's ID and Account Number can be found on Manage
          Customers Page
        </p>
        <form className="form">
          <Input
            label="customer's ID"
            placeholder="eg: Xa05gsr01Faa6wUOiy1WOEo3QAb2"
            name="id"
            onChange={onChange}
            value={transInfo.id}
          />

          <Input
            label="sender's account number"
            placeholder="Customer Acc# If Sending"
            type="number"
            name="senderAccNum"
            onChange={onChange}
            value={transInfo.senderAccNum}
          />

          <Input
            label="receipient's account number"
            placeholder="Customer Acc# If Receiving"
            type="number"
            name="receipAccNum"
            onChange={onChange}
            value={transInfo.receipAccNum}
          />

          <Input
            label="routing number"
            type="text"
            name="routingNum"
            onChange={onChange}
            value={transInfo.routingNum}
          />

          <Input
            label="amount"
            type="number"
            name="amount"
            onChange={onChange}
            value={transInfo.amount}
          />

          <Input
            label="date"
            type="date"
            name="date"
            onChange={onChange}
            value={transInfo.date}
          />

          <Input
            label="description"
            type="textarea"
            name="description"
            onChange={onChange}
            value={transInfo.description}
          />

          <div className="space-y-4 md:my-5">
            <label htmlFor="" className="text-sm text-gray-500 capitalize">
              Choose transaction status
            </label>
            <Select
              name="status"
              colorScheme="primary"
              onChange={onChange}
              placeholder="Select"
              className="text-gray-500 capitalize focus:border-4 focus:border-primary focus:ring-0"
            >
              <option value="successful">Successful</option>
              <option value="failed">Failed</option>
            </Select>
          </div>
        </form>

        <div className="flex justify-end">
          <Button
            isLoading={creating}
            className="text-white bg-primary"
            onClick={createTransaction}
            isDisabled={
              transInfo.id === "" ||
              transInfo.senderAccNum === "" ||
              transInfo.receipAccNum === "" ||
              transInfo.routingNum === "" ||
              transInfo.amount === "" ||
              transInfo.date === "" ||
              transInfo.description === "" ||
              transInfo.status === ""
            }
          >
            Create Transaction
          </Button>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default BankTransfers;
