import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit3, FiUsers } from "react-icons/fi";
import { Link, useRouteMatch } from "react-router-dom";

//utils
import addCommas from "../../utils/addCommas";

//actions
import { getCustomersAsync } from "../../redux/customers/customers-actions";

//chakra ui
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
  Skeleton,
  StatNumber,
  Badge,
} from "@chakra-ui/react";

//components
import PageTemplate from "../../components/PageTemplate";
import CustomStat from "../../components/Stat";

const AllCustomers = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.customers.isFetching);
  const customers = useSelector((state) => state.customers.customers);
  const toast = useToast();
  const { url } = useRouteMatch();

  React.useEffect(() => {
    const fetchCustomers = () => {
      try {
        dispatch(getCustomersAsync());
      } catch (error) {
        return toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchCustomers();
  }, [dispatch, toast]);

  return (
    <PageTemplate
      heading="all customers"
      subHeading=" Manage Customer Information"
      icon={<FiUsers />}
    >
      <Skeleton isLoaded={!loading}>
        <Table variant="striped" colorScheme="purple" className="text-sm">
          <TableCaption>
            {customers.length === 0 && "No customers"}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Account ID</Th>
              <Th>Account Number</Th>
              <Th>Name</Th>
              <Th>Location</Th>
              <Th>Account Balance ($)</Th>
              <Th>Allow Tranfers</Th>
              <Th>Edit</Th>
            </Tr>
          </Thead>
          <Tbody>
            {customers.length > 0 ? (
              <>
                {customers.map((customer) => {
                  const balance = Number(customer.balance).toFixed(2);

                  return (
                    <Tr key={customer.id}>
                      <Td className="font-bold">{customer.id}</Td>
                      <Td className="text-lg font-bold">
                        {customer.accountNum}
                      </Td>
                      <Td className="capitalize">{customer.name}</Td>
                      <Td className="capitalize">
                        <p>{customer.city}</p>
                        <p>{customer.state}</p>
                        <p>{customer.country}</p>
                      </Td>
                      <Td>
                        <CustomStat
                          number={<StatNumber>{addCommas(balance)}</StatNumber>}
                        />
                      </Td>
                      <Td>
                        {customer.allowTransfers ? (
                          <Badge variant="solid" colorScheme="green">
                            allowed
                          </Badge>
                        ) : (
                          <Badge variant="solid" colorScheme="red">
                            denied
                          </Badge>
                        )}
                      </Td>
                      <Td>
                        <Link to={`${url}/${customer.id}`}>
                          <FiEdit3 />
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </>
            ) : null}
          </Tbody>
        </Table>
      </Skeleton>
    </PageTemplate>
  );
};

export default AllCustomers;
