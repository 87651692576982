import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../../components/website/Layout";

//chakra-ui
import { Button, ListItem, UnorderedList } from "@chakra-ui/react";

const Trust = () => {
  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-right-bottom md:h-60 xl:items-start padding-h bg-investlg-bg">
          <div className="w-full">
            <Button className="bg-primary">
              <Link to="/services">Back</Link>
            </Button>
          </div>

          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Trust & Corporate Services
          </h1>
        </div>

        <div className="py-20 space-y-5 padding-h">
          <p>
            Royal Scotia provides its clients with comprehensive and
            personalized trustee and corporate administration services,
            including the provision of a company’s registered office, corporate
            director and secretary, accounting services, and the maintenance of
            corporate records and regulatory filings.
          </p>

          <p>
            In its Cayman offices, Royal Scotia’s Trust and Corporate Services
            Teams administer a wide range of entities, including those listed
            below.
          </p>

          <h1 className="text-xl font-semibold capitalize text-primary">
            Trustee Services
          </h1>

          <UnorderedList>
            <ListItem>Discretionary and Non-Discretionary Trusts</ListItem>
            <ListItem>
              Charitable & non-charitable purpose trusts (STAR)
            </ListItem>
            <ListItem>Private Trust Companies</ListItem>
            <ListItem>Reserved powers trusts</ListItem>
            <ListItem>Domestic Cayman Trusts</ListItem>
            <ListItem>Employee Benefit Trusts</ListItem>
          </UnorderedList>

          <h1 className="text-xl font-semibold capitalize text-primary">
            Corporate & Other Entities
          </h1>

          <UnorderedList>
            <ListItem>Exempted Companies</ListItem>
            <ListItem>International Business Companies (IBC)</ListItem>
            <ListItem>Resident or non-resident companies</ListItem>
            <ListItem>Segregated Portfolio Companies</ListItem>
            <ListItem>Companies Limited by Guarantee</ListItem>
            <ListItem>Foundations</ListItem>
            <ListItem>Special Purpose Vehicles</ListItem>
          </UnorderedList>

          <p>
            For many wealthy families and individuals, trusts and corporate
            entities form an essential component of their estate and wealth
            management plan. Common reasons for establishing an offshore
            structure include: asset protection from unforeseen potential
            creditors or litigation; planning for the succession of property and
            wealth; and preserving privacy, particularly when kidnapping or
            violence is a concern.
          </p>

          <p>
            We would be pleased to discuss your estate with you and your
            advisors.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Trust;
