import React from "react";

const Footer = () => {
  return (
    <div className="flex items-center justify-center py-3 text-white bg-secondary padding-h">
      <h1 className="text-center">
        &copy; {new Date().getFullYear()} Royal Scotia Bank & Trust. All rights
        reserved.
      </h1>
    </div>
  );
};

export default Footer;
