import React from "react";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

//chakra ui
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

//components
import MenuLink from "./MenuLink";

const Navigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <div className="sticky top-0 z-50 flex items-center justify-between h-16 bg-white text-secondary padding-h">
      <Link to="/">
        <img src={logo} alt="" className="w-48 border border-black md:w-60 " />
      </Link>

      <div className="flex items-center justify-center space-x-2 text-sm font-medium capitalize lg:space-x-10">
        <ul className="hidden lg:flex space-x-7 ">
          <MenuLink link="/" text="home" />
          <MenuLink link="/services" text="services" />
          <MenuLink link="/about-us" text="about us" />
          <MenuLink link="/contact-us" text="contact us" />
        </ul>

        <ul className="flex">
          <MenuLink link="/login" text="log in" />
        </ul>

        <Button
          ref={btnRef}
          onClick={onOpen}
          className="p-0 bg-transparent lg:hidden"
        >
          <FiMenu className="w-6 h-6" />
        </Button>
      </div>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody className="flex flex-col justify-center">
              <div className="pl-10 space-y-10 text-xl font-medium capitalize text-secondary">
                <ul className="space-y-5 ">
                  <MenuLink link="/" text="home" />
                  <MenuLink link="/services" text="services" />
                  <MenuLink link="/about-us" text="about us" />
                  <MenuLink link="/contact-us" text="contact us" />
                </ul>
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </div>
  );
};

export default Navigation;
