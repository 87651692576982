import authActionTypes from "./auth-action-types";

const INITIAL_STATE = {
  user: null,
  loading: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN:
      return {
        ...state,
        loading: true,
      };

    case authActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case authActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };

    case authActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

export default authReducer;
