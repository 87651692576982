import customersActionTypes from "./customers-action-types";
import { auth, firestore } from "../../firebase/firebaseClient";
import faker from "faker/locale/en_US";
import emailjs from "emailjs-com";

const createCustomer = () => ({
  type: customersActionTypes.CREATE_CUSTOMER,
});

const createCustomerSuccess = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_SUCCESS,
});

const createCustomerError = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_ERROR,
});

export const createCustomerAsync = (details) => {
  const { email, firstName, lastName, password, ...rest } = details;

  return async (dispatch) => {
    dispatch(createCustomer());

    try {
      const accountNum = faker.finance.account();

      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      // auth.sendPasswordResetEmail(email)

      const userDocRef = firestore.doc(`users/${user.uid}`);
      const userDocSnapshot = await userDocRef.get();

      if (!userDocSnapshot.exists) {
        const createdAt = new Date();
        const name = `${firstName} ${lastName}`;

        await userDocRef.set({
          name,
          email,
          createdAt,
          role: "customer",
          allowTransfers: true,
          active: true,
          accountNum,
          balance: `0`,
          ...rest,
        });

        //send email to customer of account creation
        await emailjs.send(
          "service_p3k1omb",
          "account_creation",
          {
            customer_name: name,
            account_number: accountNum,
            customer_email: email,
          },
          "user_IbAPCxtOv0rLhIeoNACDH"
        );

        dispatch(createCustomerSuccess());
      }
    } catch (error) {
      dispatch(createCustomerError());
      throw new Error(`error creating customer, ${error.message}`);
    }
  };
};

const getCustomers = () => ({
  type: customersActionTypes.GET_CUSTOMERS,
});

const getCustomersSuccess = (customers) => ({
  type: customersActionTypes.GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersAsync = () => {
  return async (dispatch) => {
    dispatch(getCustomers());

    try {
      const querySnapshot = await firestore
        .collection("users")
        .where("role", "==", "customer")
        .get();

      const customers = querySnapshot.docs.map((docSnapshot) => {
        return {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
      });

      dispatch(getCustomersSuccess(customers));
    } catch (error) {
      throw new Error(`${error.message}`);
    }
  };
};

const editInfo = () => ({
  type: customersActionTypes.EDIT_CUSTOMER_INFO,
});

const editSuccess = () => ({
  type: customersActionTypes.EDIT_SUCCESS,
});

export const editAsync = (userInfo) => {
  const { id, ...rest } = userInfo;

  return async (dispatch) => {
    dispatch(editInfo());

    try {
      await firestore.doc(`users/${id}`).update(rest);

      dispatch(editSuccess());
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

// const deleteQueryBatch = async (snapshot) => {
//   const batchSize = snapshot.size;
//   if (batchSize === 0) {
//     return;
//   }

//   // Delete documents in a batch
//   const batch = firestore.batch();

//   snapshot.docs.forEach((doc) => {
//     batch.delete(doc.ref);
//   });

//   await batch.commit();
// };

const deleteCustomer = () => ({
  type: customersActionTypes.DELETE_CUSTOMER,
});

const deleteSuccess = () => ({
  type: customersActionTypes.DELETE_SUCCESS,
});

export const deleteAync = (id) => {
  return async (dispatch) => {
    dispatch(deleteCustomer());

    try {
      await firestore.doc(`users/${id}`).delete();

      // const userTransactions = await firestore
      //   .collection("transactions")
      //   .where("id", "==", id)
      //   .get();

      // await deleteQueryBatch(userTransactions);

      dispatch(deleteSuccess());
    } catch (error) {
      throw new Error(error.message);
    }
  };
};
