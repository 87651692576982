import React from "react";
import { countries } from "../utils/countries";

//chakra ui
import { Select } from "@chakra-ui/react";

const CustomSelect = ({ onChange, name }) => {
  return (
    <div className="space-y-4 md:my-5">
      <label htmlFor="" className="text-sm text-gray-500 capitalize">
        select country
      </label>
      <Select
        name={name}
        colorScheme="primary"
        onChange={onChange}
        placeholder="Select"
        className="text-gray-500 focus:border-4 focus:border-primary focus:ring-0"
      >
        {countries.map((country, idx) => {
          return (
            <option key={idx} value={country.name}>
              {country.name}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

export default CustomSelect;
