import React from "react";

//chakra ui
import { Stat, StatLabel } from "@chakra-ui/react";

const CustomStat = ({ number, label, arrow }) => {
  return (
    <Stat>
      <div className="flex items-center space-x-2">
        {number}
        {arrow}
      </div>

      {label && <StatLabel className="capitalize">{label}</StatLabel>}
    </Stat>
  );
};

export default CustomStat;
