import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../components/website/Layout";

//chakra-ui
import { Button } from "@chakra-ui/react";

const About = () => {
  const team = [
    {
      name: "Eugene Douglas JD, CPA, CA, TEP",
      title: "Managing Director",
      about:
        "Eugene Douglas is the Managing Director of Royal Scotia Bank & Trust in Grand Cayman. Eugene provides personalized trust and global investment management services to high net worth families and their advisors. Prior to joining Royal Scotia Eugenespecialized in tax law and previously was Executive Vice-President of a family office based in Toronto.",
    },
    {
      name: "Lukas Vandevelde CA",
      title: "Private Banking Associate",
      about: `Lukas Vandevelde is a Private Banking Associate with Royal Scotia’s Cayman Office. Lukas is responsible for daily management of client banking as well as advising private clients on their financial needs over the short, medium and longer terms.
      Lukas has a Bachelor of Arts in Accounting & Finance from Newcastle University, England and in 2011 qualified as a Chartered Accountant as a member of the Institute of Chartered Accountants Scotland.`,
    },
    {
      name: "Shresth Marar LLB, TEP, AMLCA",
      title: " General Manager",
      about: `Shresth Marar is the General Manager of Royal Scotia and oversees its operations locally. Shresth is an Honors Graduate of the University of the West Indies and an Attorney-at-Law with extensive experience in private practice and the financial services sector.  Shresth is certified as a Trust and Estate Practitioner by the Society of Trust and Estate Practitioners (STEP) in International Trust Management and is qualified as an Anti-money Laundering Certified Associate with the Florida International Bankers Association.`,
    },
    {
      name: "Kaitlyn Walsh LL.B, CAMS",
      title: "Group Chief Compliance Officer",
      about: `Kaitlyn Walsh is the Group Chief Compliance Officer and brings to Royal Scotia over fifteen years of compliance experience. Kaitlyn joined Royal Scotia from the Royal Bank of Canada where for seven years she was the Regional Head of Compliance for RBC Wealth Management (Caribbean) business with offices in Grand Cayman, Barbados and the Bahamas. Kaitlyn played a pivotal role in the divestiture of that business which was completed in May 2017.`,
    },
    {
      name: "Herbert Pierre Jr. LL.M, TEP",
      title: "Private Banker",
      about: `In his role as Private Banker,Herbert Pierre Jr manages a portfolio of complex international estate structures based in Cayman, including personal trusts, purpose trusts and private trust companies. With over ten years of experience in the trust industry, Herbert assists clients with the establishment and administration of their estate structures.
`,
    },
    {
      name: "Scarlett Halvorson CA",
      title: "Investment Accountant",
      about: `Scarlett Halvorsonis an Investment Accountant with Royal Scotia’s Cayman office. Scarlett is responsible for the NAV calculation and accounting for The Royal Scotia Fund. She is a Fellow of the Institute of Chartered Accountants of England and Wales and holds a BA (Hons) degree in Economics from the University of Newcastle Upon Tyne, UK.`,
    },
  ];

  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-right xl:bg-center md:h-60 xl:items-start padding-h bg-about-bg">
          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            About Us
          </h1>
        </div>

        <div className="py-20 space-y-10 padding-h">
          <p>
            Royal Scotia Bank & Trust was established in 1990 in Grand Cayman,
            Cayman Islands. We are licensed as a Class “B” Bank and Trust
            Company allowing us to provide banking and trustee services to
            international clients.
          </p>

          <p>
            In Cayman, Royal Scotia holds a restricted Mutual Fund Administrator
            Licence and a Securities Investment Business Licence. These licenses
            enable us to provide investment management services as well as
            securities trading and related services. Royal Scotia Bank & Trust
            is licensed and regulated by the Cayman Islands Monetary Authority.
          </p>
        </div>

        <div className="py-20 space-y-10 padding-h">
          <h1 className="text-4xl text-center capitalize">Meet the team</h1>

          <div className="grid gap-10 lg:grid-cols-2">
            {team.map((member, idx) => {
              return (
                <div key={idx} className="space-y-10">
                  <h1 className="text-2xl text-primary">{member.name}</h1>
                  <p>{member.about}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-20 space-y-10 text-white bg-primary">
          <h1 className="text-3xl ">Apply for an account in minutes</h1>

          <p>
            Send us a notification and we will get in touch with you to complete
            your account creation process.
          </p>

          <Button className="bg-secondary text-primary" size="lg">
            <Link to="/contact-us"> Get Your Royal Scotia Bank Account</Link>
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default About;
