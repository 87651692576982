import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../components/website/Layout";

const Services = () => {
  const services = [
    {
      title: "Private & Online Banking",
      image: "bg-private-bg",
      link: "/services/private-banking",
    },
    {
      title: "Trust & Corporate Services",
      image: "bg-invest-bg",
      link: "/services/corporate-services",
    },
    {
      title: " wealth management",
      image: "bg-wealth-bg",
      link: "/services/wealth-management",
    },
    {
      title: "The Royal Scotia Fund",
      image: "bg-fund-bg",
      link: "/services/royal-scotia-fund",
    },
  ];
  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-bottom md:h-60 xl:items-start padding-h bg-services-bg">
          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Services
          </h1>
        </div>

        <div className="py-20 space-y-20 padding-h">
          <div className="space-y-5">
            <p>
              At Royal Scotia Bank we offer personalized services that are
              tailored to each of our client’s unique needs. Please select from
              the categories below to learn more about each of our service
              offerings.
            </p>

            <p>
              For many of our clients a diversified portfolio of the sub-funds
              of The Royal Scotia Fund is appropriate. For families of
              substantial wealth Royal Scotia also offers segregated investment
              and global custody services that are managed by dedicated
              professional portfolio managers.
            </p>

            <p>
              Please select from the menu below to learn more about each of
              Royal Scotia’s principal lines of service.
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2">
            {services.map((service, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex items-center justify-center bg-center group h-80  ${service.image}`}
                >
                  <div className="flex items-center justify-center w-1/2 p-5 transition-all duration-300 ease-in cursor-pointer bg-opacity-60 xl:bg-opacity-40 h-1/2 bg-primary group-hover:bg-opacity-70">
                    <h1 className="text-sm text-center text-white capitalize xl:text-xl">
                      <Link to={service.link}> {service.title}</Link>
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Services;
