import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

//pages
import AllCustomers from "./all-customers";
import EditCustomer from "./edit-customer";

//components
import Layout from "../../components/Layout";
import AuthRoute from "../../components/AuthRoute";

const ManageCustomers = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <AuthRoute exact path={path} Component={AllCustomers} />
        <AuthRoute path={`${path}/:id`} Component={EditCustomer} />
      </Switch>
    </Layout>
  );
};

export default ManageCustomers;
