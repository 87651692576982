import React from "react";

//chakra ui
import {
  Input,
  NumberInput,
  NumberInputField,
  Textarea,
} from "@chakra-ui/react";

const FormInput = ({
  label,
  type,
  required,
  name,
  onChange,
  value,
  disabled,
  placeholder,
}) => {
  let inputType;

  if (type === "number") {
    inputType = (
      <NumberInput value={value} isDisabled={disabled}>
        <NumberInputField
          className="text-gray-500 focus:border-4 focus:border-primary focus:ring-0"
          name={name}
          onChange={onChange}
          placeholder={placeholder}
        />
      </NumberInput>
    );
  } else if (type === "textarea") {
    inputType = (
      <Textarea
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className="text-gray-500 focus:border-4 focus:border-primary focus:ring-0"
      />
    );
  } else {
    inputType = (
      <Input
        type={type}
        isDisabled={disabled}
        isRequired={required}
        placeholder={placeholder}
        className="text-gray-500 focus:border-4 focus:border-primary focus:ring-0"
        name={name}
        onChange={onChange}
        value={value}
      />
    );
  }

  return (
    <div className="space-y-4 md:my-5">
      <label htmlFor={name} className="text-sm text-gray-500 capitalize">
        {label}
      </label>
      {inputType}
    </div>
  );
};

export default FormInput;
