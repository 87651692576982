import React from "react";
import {
  RiAccountPinCircleLine,
  RiPhoneLine,
  RiUserLocationLine,
  RiThumbUpLine,
  RiThumbDownLine,
} from "react-icons/ri";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import { MdAccountBalance } from "react-icons/md";
import { useSelector } from "react-redux";

//utils
import { getCurrentInfo } from "../../utils/getCurrentInfo";

//components
import Layout from "../../components/Layout";
import PageTemplate from "../../components/PageTemplate";
import Card from "../../components/Card";

const AccountDetails = () => {
  const { user } = useSelector((state) => state.user);

  const [info, setInfo] = React.useState(user);
  // console.log(info);

  // : { id, name, email, phoneNum, country, accountNum, active },

  React.useEffect(() => {
    const unsubscribe = getCurrentInfo(user.id, setInfo);

    return () => unsubscribe();
  }, [user.id]);

  return (
    <Layout>
      <PageTemplate
        heading="account details"
        subHeading="account holder's overview"
        icon={<RiAccountPinCircleLine />}
      >
        <div className="grid gap-3 md:grid-cols-2 xl:grid-cols-3">
          <Card>
            <div className="flex space-x-3 md:space-x-5">
              <AiOutlineUser className="icon" />
              <div>
                <h3 className="text-sm subHeading">Account Holder</h3>
                <p className="text-md">{info.name}</p>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex space-x-3 md:space-x-5">
              <AiOutlineMail className="icon" />
              <div>
                <h3 className="text-sm subHeading">Account Holder's Email</h3>
                <p className="text-md">{info.email}</p>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex space-x-3 md:space-x-5">
              <RiPhoneLine className="icon" />
              <div>
                <h3 className="text-sm subHeading">Account Holder's Phone</h3>
                <p className="text-md">{info.phoneNum}</p>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex space-x-3 md:space-x-5">
              <RiUserLocationLine className="icon" />
              <div>
                <h3 className="text-sm subHeading">Account Holder's Country</h3>
                <p className="text-md">{info.country}</p>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex space-x-3 md:space-x-5">
              <MdAccountBalance className="icon" />
              <div>
                <h3 className="text-sm subHeading">Account Number</h3>
                <p className="text-md">{info.accountNum}</p>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex space-x-3 md:space-x-5">
              {info.active ? (
                <RiThumbUpLine className="icon " />
              ) : (
                <RiThumbDownLine className="w-8 h-8 text-red-600 md:w-10 md:h-10" />
              )}
              <div>
                <h3 className="text-sm subHeading">Account Status</h3>
                <p className="text-md">{info.active ? "Active" : "Inactive"}</p>
              </div>
            </div>
          </Card>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default AccountDetails;
