import React from "react";

const PageTemplate = ({ heading, subHeading, children, icon, link }) => {
  return (
    <div className="page">
      {link}
      <h1 className="heading">{heading}</h1>
      <div className="inner-container">
        <div className="flex items-center space-x-2">
          {icon}
          <h3 className="text-lg font-semibold capitalize">{subHeading}</h3>
        </div>

        {children}
      </div>
    </div>
  );
};

export default PageTemplate;
