import authActionTypes from "./auth-action-types";
import { auth, firestore } from "../../firebase/firebaseClient";

const login = () => ({
  type: authActionTypes.LOGIN,
});

const setUser = (user) => ({
  type: authActionTypes.SET_USER,
  payload: user,
});

export const loginFailed = () => ({
  type: authActionTypes.LOGIN_FAILURE,
});

export const loginAsync = (credentials) => {
  const { email, password } = credentials;

  return async (dispatch) => {
    dispatch(login());

    try {
      const { user } = await auth.signInWithEmailAndPassword(
        email.trim(),
        password.trim()
      );

      const userRef = firestore.doc(`users/${user.uid}`);
      const snapShot = await userRef.get();

      if (snapShot.exists) {
        dispatch(
          setUser({
            id: snapShot.id,
            ...snapShot.data(),
          })
        );
      } else {
        throw new Error("Sorry your account does not exist");
      }
    } catch (err) {
      dispatch(loginFailed());
      throw new Error(err.message);
    }
  };
};

const logOut = () => ({
  type: authActionTypes.LOGOUT,
});

export const logOutAsync = () => {
  return async (dispatch) => {
    dispatch(logOut());

    await auth.signOut();
  };
};
