import { combineReducers } from "redux";

//reducers
import authReducer from "./auth/auth-reducer";
import customersReducer from "./customers/customers-reducer";
import transReducer from "./transactions/trans-reducer";

export default combineReducers({
  user: authReducer,
  customers: customersReducer,
  transactions: transReducer,
});
