import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../../components/website/Layout";

//chakra-ui
import { Button } from "@chakra-ui/react";

const Private = () => {
  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-bottom md:h-60 xl:items-start padding-h bg-privatelg-bg">
          <div className="w-full">
            <Button className="bg-primary">
              <Link to="/services">Back</Link>
            </Button>
          </div>

          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Online & Private Banking
          </h1>
        </div>

        <div className="py-20 space-y-5 padding-h">
          <p>
            Royal Scotia offers qualified persons with personalized offshore
            online and private banking services including taking deposits in all
            major currencies, foreign exchange and secured lending.
          </p>

          <p>
            At Royal Scotia we maintain a number of correspondent banking
            relationships with premier international financial institutions –
            both onshore and offshore. This approach provides us and our clients
            with access to the best cash management solutions available while
            ensuring flexibility at critical times.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Private;
