import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      100: "#1abc9c",
      500: "#1abc9c",
    },
  },
});
