import transActionTypes from "./trans-action-types";
import { firestore } from "../../firebase/firebaseClient";
import faker from "faker/locale/en_US";
import moment from "moment";

const createTrans = () => ({
  type: transActionTypes.CREATE_TRANS,
});

const createTransSuccess = () => ({
  type: transActionTypes.CREATE_TRANS_SUCCESS,
});

const createTransError = () => ({
  type: transActionTypes.CREATE_TRANS_ERROR,
});

export const createTransAsync = (transInfo) => {
  return async (dispatch) => {
    const { id, senderAccNum, receipAccNum, amount, ...rest } = transInfo;

    dispatch(createTrans());

    try {
      if (senderAccNum === receipAccNum) {
        throw new Error("Account numbers cannot be the same");
      }

      let isReceived;
      let newBalance;
      let transType = "transfer";

      const type = faker.finance.transactionType();

      const userRef = firestore.doc(`users/${id.trim()}`);

      const snapShot = await userRef.get();

      if (snapShot.exists) {
        const { accountNum, name, balance } = snapShot.data();

        if (senderAccNum.trim() === accountNum) {
          isReceived = false;
          if (type === "invoice" || type === "payment") {
            transType = type;
          }

          if (transInfo.status === "successful") {
            if (Number(balance) > Number(amount)) {
              newBalance = Number(balance) - Number(amount);
            } else {
              throw new Error("Insufficient funds for this transaction");
            }
          } else {
            newBalance = Number(balance);
          }
        } else if (receipAccNum.trim() === accountNum) {
          isReceived = true;
          if (type === "payment") {
            transType = type;
          }

          if (transInfo.status === "successful") {
            newBalance = Number(balance) + Number(amount);
          } else {
            newBalance = Number(balance);
          }
        } else {
          throw new Error("Customer Account Number Is Wrong. Check again");
        }

        await firestore.collection("transactions").add({
          ...transInfo,
          accountNum,
          name,
          isReceived,
          type: transType,
          createdAt: new Date(),
          balance: `${newBalance}`,
          ...rest,
        });

        if (newBalance) {
          await userRef.update({
            balance: `${newBalance}`,
          });
        }

        dispatch(createTransSuccess());
      } else {
        throw new Error("Customer does not exist");
      }
    } catch (error) {
      dispatch(createTransError());
      throw new Error(`${error.message}`);
    }
  };
};

const getAllTrans = () => ({
  type: transActionTypes.GET_ALL_TRANS,
});

const getCustomerTrans = () => ({
  type: transActionTypes.GET_CUSTOMER_TRANS,
});

const getTransSuccess = (transactions) => ({
  type: transActionTypes.GET_TRANS_SUCCESS,
  payload: transactions,
});

export const getAllTransAsync = () => {
  return async (dispatch) => {
    dispatch(getAllTrans());

    try {
      const querySnapshot = await firestore
        .collection("transactions")
        .orderBy("createdAt", "desc")
        .get();

      const transactions = querySnapshot.docs.map((docSnapshot) => {
        return {
          transId: docSnapshot.id,
          ...docSnapshot.data(),
        };
      });
      dispatch(getTransSuccess(transactions));
    } catch (error) {
      throw new Error(`${error.message}`);
    }
  };
};

export const getCustomerTransAsync = (id) => {
  return async (dispatch) => {
    dispatch(getCustomerTrans());

    try {
      const querySnapshot = await firestore
        .collection("transactions")
        .where("id", "==", `${id}`)
        .orderBy("createdAt", "desc")
        .get();

      const transactions = querySnapshot.docs.map((docSnapshot) => {
        return {
          transId: docSnapshot.id,
          ...docSnapshot.data(),
        };
      });

      dispatch(getTransSuccess(transactions));
    } catch (error) {
      throw new Error(`${error.message}`);
    }
  };
};

const deleteTrans = () => ({
  type: transActionTypes.DELETE_TRANS,
});

const deleteTransSuccess = () => ({
  type: transActionTypes.DELETE_TRANS_SUCCESS,
});

const deleteTransError = () => ({
  type: transActionTypes.DELETE_TRANS_ERROR,
});

export const deleteTransAsync = (transaction) => {
  return async (dispatch) => {
    dispatch(deleteTrans());

    const { transId, amount, id, isReceived } = transaction;

    try {
      let newBalance;
      const userRef = firestore.doc(`users/${id}`);
      const snapshot = await userRef.get();

      if (snapshot.exists) {
        const { balance } = snapshot.data();

        //update balance
        if (transaction.status === "successful") {
          if (isReceived) {
            newBalance = Number(balance) - Number(amount);
          } else {
            newBalance = Number(balance) + Number(amount);
          }
        }

        if (newBalance !== undefined) {
          userRef.update({
            balance: `${newBalance}`,
          });
        }
      }

      //delete transaction
      await firestore.doc(`transactions/${transId}`).delete();

      dispatch(deleteTransSuccess());
    } catch (error) {
      dispatch(deleteTransError());
      throw new Error(`${error.message}`);
    }
  };
};

export const makeTransfer = (details) => {
  const { receipAccNum, amount, id, accountNum, ...rest } = details;

  return async (dispatch) => {
    dispatch(createTrans());

    try {
      if (accountNum === receipAccNum) {
        throw new Error("Account numbers cannot be the same");
      }

      let newBalance;

      const userRef = firestore.doc(`users/${id.trim()}`);

      const snapShot = await userRef.get();

      if (snapShot.exists) {
        const { name, balance, allowTransfers } = snapShot.data();
        const date = moment(new Date()).format().split("T")[0];

        if (allowTransfers) {
          if (Number(balance) > Number(amount)) {
            newBalance = Number(balance) - Number(amount);
          } else {
            throw new Error("Insufficient funds for this transaction");
          }

          await firestore.collection("transactions").add({
            id,
            senderAccNum: accountNum,
            receipAccNum,
            amount,
            accountNum,
            name,
            date,
            balance: `${newBalance}`,
            isReceived: false,
            type: "transfer",
            status: "successful",
            createdAt: new Date(),
            ...rest,
          });

          if (newBalance) {
            await userRef.update({
              balance: `${newBalance}`,
            });
          }
        } else {
          await firestore.collection("transactions").add({
            id,
            senderAccNum: accountNum,
            receipAccNum,
            amount,
            accountNum,
            name,
            date,
            balance: `${newBalance}`,
            isReceived: false,
            type: "transfer",
            status: "failed",
            createdAt: new Date(),
            ...rest,
          });

          throw new Error("Transfer could not be completed. Try again");
        }

        dispatch(createTransSuccess());
      } else {
        throw new Error("Customer does not exist");
      }
    } catch (error) {
      dispatch(createTransError());
      throw new Error(`${error.message}`);
    }
  };
};
