import React from "react";
import { FiUserPlus } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

//actions
import { createCustomerAsync } from "../../redux/customers/customers-actions";

//chakra ui
import { Button, useToast } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import Input from "../../components/FormInput";
import Select from "../../components/Select";
import PageTemplate from "../../components/PageTemplate";

const NewCustomers = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.customers.loading);
  const toast = useToast();

  const history = useHistory();

  const [details, setDetails] = React.useState({
    firstName: "",
    lastName: "",
    phoneNum: "",
    email: "",
    country: "",
    state: "",
    city: "",
    password: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    setDetails({
      ...details,
      [name]: value.trim(),
    });
  };

  const createAccount = async () => {
    try {
      await dispatch(createCustomerAsync(details));

      toast({
        title: "Account created.",
        description: "Account created successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      history.push("/admin/manage-customers");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setDetails({
        firstName: "",
        lastName: "",
        phoneNum: "",
        email: "",
        country: "",
        state: "",
        city: "",
        password: "",
      });
    }
  };

  const {
    firstName,
    lastName,
    phoneNum,
    email,
    country,
    state,
    city,
    password,
  } = details;

  return (
    <Layout>
      <PageTemplate
        heading="new customer"
        subHeading="Create new customer"
        icon={<FiUserPlus />}
      >
        <form className="form">
          <Input
            label="customer's first name"
            required
            onChange={onChange}
            name="firstName"
            value={details.firstName}
          />

          <Input
            label="customer's last name"
            onChange={onChange}
            name="lastName"
            value={details.lastName}
          />

          <Input
            label="customer's phone number"
            type="number"
            onChange={onChange}
            name="phoneNum"
            value={details.phoneNum}
          />

          <Input
            label="customer's email"
            type="email"
            onChange={onChange}
            name="email"
            value={details.email}
          />

          <Select onChange={onChange} name="country" />

          <Input
            label="enter state"
            onChange={onChange}
            name="state"
            value={details.state}
          />

          <Input
            label="enter city"
            onChange={onChange}
            name="city"
            value={details.city}
          />

          <Input
            label="login password"
            onChange={onChange}
            name="password"
            value={details.password}
          />
        </form>

        <div className="flex justify-end">
          <Button
            isLoading={loading}
            className="text-white bg-primary"
            onClick={createAccount}
            isDisabled={
              firstName === "" ||
              lastName === "" ||
              phoneNum === "" ||
              email === "" ||
              country === "" ||
              state === "" ||
              city === "" ||
              password === ""
            }
          >
            Open Account
          </Button>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default NewCustomers;
