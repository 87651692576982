import React from "react";
import { useSelector, useDispatch } from "react-redux";

//actions
import { logOutAsync } from "../redux/auth/auth-actions";

//chakra ui
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

//icons
import { FiLogOut, FiMenu } from "react-icons/fi";

//components
import AdminNav from "./admin/Navigation";
import CustomerNav from "./customer/Navigation";

const Layout = ({ children }) => {
  const { name, role } = useSelector((state) => state.user.user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logOutAsync());
  };

  return (
    <div className="relative w-screen h-screen md:overflow-x-hidden">
      <div className="sticky top-0 z-50 flex items-center justify-end h-16 shadow-md bg-primary padding-h">
        <div className="flex items-center space-x-3">
          <Avatar name={name} size="sm" />
          <Menu>
            <MenuButton className="outline-none">
              <h1 className="hidden font-medium text-white capitalize text-md xl:block">
                {name}
              </h1>
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<FiLogOut />}
                className="hover:text-red-600 focus:outline-none"
                onClick={signOut}
              >
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>

          <Button
            ref={btnRef}
            onClick={onOpen}
            className="p-0 bg-transparent xl:hidden"
          >
            <FiMenu className="w-6 h-6 text-white" />
          </Button>
        </div>
      </div>

      <div className="relative grid grid-cols-1 xl:h-600 grid-row-1 xl:grid-cols-12">
        <div className="hidden col-span-2 overflow-y-auto text-white xl:block bg-secondary">
          {role === "admin" ? <AdminNav /> : <CustomerNav />}
        </div>

        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay>
            <DrawerContent className="text-white bg-secondary">
              <DrawerCloseButton className="outline-none focus:ring-0 ring-0" />
              <DrawerHeader>
                <div className="flex items-center space-x-3">
                  <Avatar name={name} size="sm" />
                  <h1 className="font-medium text-white capitalize text-md">
                    {name}
                  </h1>
                </div>
              </DrawerHeader>

              <DrawerBody>
                {role === "admin" ? <AdminNav /> : <CustomerNav />}
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>

        <div className="col-span-10 overflow-y-auto xl:col-start-3">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
