import React from "react";
import bgImg from "../assets/bank.jpg";
import { useDispatch, useSelector } from "react-redux";

//actions
import { loginAsync } from "../redux/auth/auth-actions";

//chakra ui
import {
  Input,
  Button,
  Divider,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

//components
import Layout from "../components/website/Layout";

const Login = () => {
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const toast = useToast();

  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      await dispatch(loginAsync(credentials));
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setCredentials({
        email: "",
        password: "",
      });
    }
  };

  return (
    <Layout>
      <div className="flex justify-center h-screen pt-20 md:pt-40 xl:pt-0 xl:items-center bg-gray-50 ">
        <div className="grid w-4/5 grid-cols-1 rounded-md md:border border-tertiary h-4/5 md:h-64 md:grid-cols-3 md:4/5 xl:w-1/2">
          <div className="relative space-y-5 text-white ">
            <img
              src={bgImg}
              alt=""
              className="z-10 w-full h-full overflow-hidden md:rounded-bl-md"
            />
            <div className="absolute left-0 z-50 w-full h-full space-y-5 bg-primary p-7 bg-opacity-80 -top-5 rounded-tl-md rounded-tr-md md:rounded-tr-none md:rounded-bl-md">
              <h1 className="text-3xl font-medium text-center capitalize md:text-left">
                login
              </h1>
              <Divider className="h-px bg-white opacity-100 " />

              <p className="font-medium text-center text-md md:text-md md:text-left">
                Login and explore the possibilities of online banking
              </p>
            </div>
          </div>

          <div className="space-y-8 md:col-span-2 p-7">
            <form className="space-y-5">
              <Input
                placeholder="Enter Email ID"
                type="email"
                name="email"
                className="focus:border-4 focus:border-primary focus:ring-0"
                onChange={handleChange}
                value={credentials.email}
              />
              <InputGroup>
                <Input
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Enter Password"
                  className="focus:border-4 focus:border-primary focus:ring-0"
                  value={credentials.password}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClick}
                    className="bg-transparent"
                  >
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </form>

            <div>
              <Button
                isLoading={loading}
                className="text-white uppercase bg-primary"
                onClick={login}
                isDisabled={
                  credentials.email === "" || credentials.password === ""
                }
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
