import React from "react";
import { RiExchangeDollarLine } from "react-icons/ri";

import { useSelector, useDispatch } from "react-redux";

//actions
import { getAllTransAsync } from "../../redux/transactions/trans-actions";

//components
import Layout from "../../components/Layout";
import TransactionsTable from "../../components/TransactionsTable";
import PageTemplate from "../../components/PageTemplate";
import Input from "../../components/FormInput";

//chakra ui
import { Skeleton } from "@chakra-ui/react";

const AllTransactions = () => {
  const [name, setName] = React.useState("");

  const transactions = useSelector((state) => state.transactions.transactions);
  const fetching = useSelector((state) => state.transactions.fetching);
  const dispatch = useDispatch();

  const [filteredTrans, setFilterTrans] = React.useState(transactions);

  const onChange = (e) => {
    const { value } = e.target;

    const trans = transactions.filter((trans) =>
      trans.name.toLowerCase().includes(value)
    );

    setName(value);
    setFilterTrans(trans);
  };

  React.useEffect(() => {
    const getTransactions = async () => {
      try {
        await dispatch(getAllTransAsync());
      } catch (error) {
        console.log(error.message);
      }
    };

    getTransactions();
  }, [dispatch]);

  return (
    <Layout>
      <PageTemplate
        heading="all transactions"
        subHeading="all customer transactions"
        icon={<RiExchangeDollarLine />}
      >
        <Input
          label="search with customer name"
          type="text"
          name="description"
          onChange={onChange}
          value={name}
        />
        <Skeleton isLoaded={!fetching}>
          <TransactionsTable transactions={filteredTrans} edit name />
        </Skeleton>
      </PageTemplate>
    </Layout>
  );
};

export default AllTransactions;
