import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../../components/website/Layout";

//chakra-ui
import { Button } from "@chakra-ui/react";

const Wealth = () => {
  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-right-bottom md:h-60 xl:items-start padding-h bg-wealthlg-bg">
          <div className="w-full">
            <Button className="bg-primary">
              <Link to="/services">Back</Link>
            </Button>
          </div>

          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Wealth Management
          </h1>
        </div>

        <div className="py-20 space-y-5 padding-h">
          <p>
            Wealth management is at the core of Royal Scotia’s business. Through
            offices in the Cayman Islands , Royal Scotia has the financial
            strength you expect while providing you with the personal service
            you deserve.
          </p>

          <p>
            Royal Scotia provides its wealth management services primarily
            through Royal Scotia Global Investment Management Limited (“RSGIM”).
            RSGIM offers clients access to a range of investment management
            services through The Royal Scotia Fund, an open-ended mutual fund
            company incorporated in the Cayman Islands since 1991, as well as
            through segregated portfolios tailored to the investment objectives
            of each client.
          </p>

          <p>
            Royal Scotia also provides asset management services and works with
            clients to select and manage third-party investment managers around
            the world.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Wealth;
