import React from "react";
import { Link } from "react-router-dom";

//chakra-ui
import { Button } from "@chakra-ui/react";

//components
import Layout from "../../components/website/Layout";
import Card from "../../components/Card";

const Home = () => {
  const name = "Royal Scotia";

  const services = [
    {
      name: "Private & Online Banking",
      description: `${name} offers qualified persons with personalized offshore
    online and private banking services...`,
      link: "/services/private-banking",
    },
    {
      name: "wealth management",
      description: `Investment advisory and administration services for institutional and private clients...`,
      link: "/services/wealth-management",
    },
    {
      name: "trust and corporate services",
      description: `A team of professional administrators based in the Cayman Islands with the experience to...`,
      link: "/services/corporate-services",
    },
    {
      name: `The ${name} Fund`,
      description: `An umbrella fund comprising seven distinct investment classes designed to complement...`,
      link: "/services/royal-scotia-fund",
    },
  ];

  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center space-y-12 text-white bg-center xl:items-start padding-h h-450 md:h-400 xl:h-600 bg-home-bg">
          <h1 className="text-4xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Trust to protect
          </h1>
        </div>

        <div className="relative">
          <div className="absolute left-0 flex items-center justify-center w-full bg-transparent -top-20 ">
            <div className="grid w-4/5 gap-3 md:grid-cols-2 xl:grid-cols-4">
              {services.map((service, idx) => {
                return (
                  <Card className="text-center hover:shadow-lg" key={idx}>
                    <h1 className="text-xl font-semibold capitalize text-primary">
                      {" "}
                      {service.name}
                    </h1>

                    <p className="">{service.description}</p>

                    <div className="font-bold ">
                      <Link
                        to={service.link}
                        className="underline hover:text-primary"
                      >
                        Learn More
                      </Link>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>

        <div className="py-24 space-y-24 mt-750 md:mt-96 lg:mt-64 xl:mt-40 padding-h">
          <div className="grid gap-8 xl:grid-cols-2 ">
            <div className="space-y-5 text-center">
              <h1 className="text-2xl md:text-4xl text-primary">{name}</h1>
              <p className=" text-secondary">
                Established in 1990, {name} offers personalized trustee,
                investment, private and online banking services to families
                around the globe.
              </p>
            </div>

            <div className="space-y-5 text-center">
              <h1 className="text-2xl capitalize md:text-4xl text-primary">
                {name} trust
              </h1>
              <p className="text-secondary">
                Based in the Cayman Islands, {name} Trust specializes in trust
                and corporate administration services for private clients and
                multi-national businesses.
              </p>
            </div>
          </div>

          <div className="flex justify-center">
            <Button className="text-white uppercase bg-primary" size="lg">
              <Link to="/services">Explore Our Services</Link>
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
