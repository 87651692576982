import React from "react";
import { GoLocation, GoMail } from "react-icons/go";

//components
import Layout from "../../components/website/Layout";
import Input from "../../components/FormInput";

//chakra-ui
import { Button, useToast } from "@chakra-ui/react";

const Contact = () => {
  const [details, setDetails] = React.useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();

  const onChange = (e) => {
    const { name, value } = e.target;

    setDetails({
      ...details,
      [name]: value,
    });
  };

  const sendMessage = async () => {
    setLoading(true);

    try {
      const response = await fetch("https://formspree.io/f/mqkgovre", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      });

      if (response.status === 200) {
        setLoading(false);

        toast({
          title: "Messag Sent.",
          description: "Message sent successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setDetails({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast({
        title: "Something went wrong.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setDetails({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-center md:h-60 xl:items-start padding-h bg-contact-bg">
          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            Contact Us
          </h1>
        </div>

        <div className="grid gap-8 py-20 lg:grid-cols-3 padding-h">
          <div className="space-y-10">
            <div className="space-y-2 capitalize">
              <GoLocation className="w-12 h-12 text-primary" />
              <p className="text-xl font-semibold text-primary">
                Royal Scotia Bank and Trust
              </p>
              <p>Cayman corporate centre</p>
              <p>27 hospital road</p>
              <p>george town, grand cayman</p>
            </div>

            <div>
              <GoMail className="w-12 h-12 text-primary" />
              <p className="text-xl font-semibold text-primary">
                info@royalscotiatrust.com
              </p>
            </div>
          </div>

          <div className="col-span-2 space-y-5">
            <form className="form">
              <Input
                label="Name"
                name="name"
                type="text"
                onChange={onChange}
                value={details.name}
              />

              <Input
                label="Email"
                type="email"
                name="email"
                onChange={onChange}
                value={details.email}
              />

              <Input
                label="Phone Number"
                type="number"
                name="phone"
                onChange={onChange}
                value={details.phone}
              />

              <Input
                label="subject"
                type="text"
                name="subject"
                onChange={onChange}
                value={details.subject}
              />
            </form>

            <Input
              label="message"
              type="textarea"
              name="message"
              onChange={onChange}
              value={details.message}
            />

            <Button
              isLoading={loading}
              className="text-white capitalize bg-primary"
              onClick={sendMessage}
              isDisabled={
                details.name === "" ||
                details.email === "" ||
                details.phone === "" ||
                details.subject === "" ||
                details.message === ""
              }
            >
              get in touch
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
