import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RiUserLine, RiAccountCircleLine } from "react-icons/ri";
import { MdAccountBalanceWallet } from "react-icons/md";
import { CgArrowLongDownR, CgArrowLongUpR } from "react-icons/cg";

//fusion chart
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

//actions
import { getCustomerTransAsync } from "../../redux/transactions/trans-actions";

//utils
import { getBalance } from "../../utils/getBalance";
import addCommas from "../../utils/addCommas";
// import {
//   balanceChartConfigs,
//   sentChartConfigs,
//   receivedChartConfigs,
// } from "../../utils/chartConfigs";

//chakra-ui
import { Skeleton, StatNumber, StatArrow, useToast } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import PageTemplate from "../../components/PageTemplate";
import Card from "../../components/Card";
import CustomStat from "../../components/Stat";
import TransactionsTable from "../../components/TransactionsTable";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const CustomerHome = () => {
  const [currBalance, setBalance] = React.useState(null);

  const toast = useToast();
  const dispatch = useDispatch();
  const { transactions, fetching } = useSelector((state) => state.transactions);
  const {
    user: { name, accountNum, id },
  } = useSelector((state) => state.user);

  const lastFiveTransactions = transactions.filter((_, idx) => idx < 29);

  const sent = transactions.filter(
    (transaction) =>
      transaction.isReceived === false && transaction.status === "successful"
  );

  const received = transactions.filter(
    (transaction) =>
      transaction.isReceived === true && transaction.status === "successful"
  );

  const outTotal = sent.reduce((acc, { amount }) => {
    return acc + Number(amount);
  }, 0);

  const inTotal = received.reduce((acc, { amount }) => {
    return acc + Number(amount);
  }, 0);

  React.useEffect(() => {
    const fetchInfo = async () => {
      try {
        await dispatch(getCustomerTransAsync(id));
      } catch (error) {
        return toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchInfo();
  }, [dispatch, id, toast]);

  React.useEffect(() => {
    const unsubscribe = getBalance(id, setBalance);

    return () => unsubscribe();
  }, [id]);

  return (
    <Layout>
      <PageTemplate
        heading={`welcome, ${name}`}
        subHeading="account summary"
        icon={<RiAccountCircleLine />}
      >
        <div className="grid gap-3 md:grid-cols-2 ">
          <Card>
            <RiUserLine className="icon" />

            <CustomStat
              label="account number"
              number={<StatNumber>{accountNum}</StatNumber>}
            />
          </Card>

          <Card>
            <MdAccountBalanceWallet className="icon" />

            <CustomStat
              label="account balance"
              number={
                <Skeleton isLoaded={currBalance}>
                  <StatNumber>
                    ${addCommas(Number(currBalance).toFixed(2))}
                  </StatNumber>
                </Skeleton>
              }
            />
          </Card>

          <Card>
            <CgArrowLongDownR className="icon" />

            <CustomStat
              label="total deposits"
              number={
                <Skeleton isLoaded={!fetching}>
                  <StatNumber>${addCommas(inTotal.toFixed(2))}</StatNumber>
                </Skeleton>
              }
              arrow={<StatArrow type="increase" />}
            />
          </Card>

          <Card>
            <CgArrowLongUpR className="icon" />

            <CustomStat
              label="total withdrawals"
              number={
                <Skeleton isLoaded={!fetching}>
                  <StatNumber>${addCommas(outTotal.toFixed(2))}</StatNumber>
                </Skeleton>
              }
              arrow={<StatArrow type="decrease" />}
            />
          </Card>
        </div>

        {/* <div className="space-y-5 ">
          <h3 className="subHeading">Statistics Chart</h3>
          <div className="flex flex-col items-center space-y-5 md:flex-row md:justify-center lg:space-x-5 md:space-y-0">
            <ReactFC {...balanceChartConfigs(currBalance)} />
            <ReactFC {...sentChartConfigs(outTotal)} />
            <ReactFC {...receivedChartConfigs(inTotal)} />
          </div>
        </div> */}

        <div className="space-y-5">
          <h3 className="subHeading">Recent Transactions</h3>

          <Skeleton isLoaded={!fetching}>
            <TransactionsTable transactions={lastFiveTransactions} />
          </Skeleton>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default CustomerHome;
