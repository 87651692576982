import React from "react";
import { useDispatch } from "react-redux";
import { FiHome } from "react-icons/fi";
import { RiAccountPinCircleLine, RiFolderHistoryLine } from "react-icons/ri";
import { IoNewspaperOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";

//actions
import { logOutAsync } from "../../redux/auth/auth-actions";

//chakra-ui
import { Button } from "@chakra-ui/react";

//components
import Section from "../NavSection";
import MenuItem from "../MenuItem";

const Navigation = () => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logOutAsync());
  };

  return (
    <div className="navigation">
      <Section title="my account">
        <MenuItem link="/online-banking" text="dashboard" icon={<FiHome />} />
      </Section>

      <Section title="funds transfer">
        <MenuItem
          link="/online-banking/transfer-funds"
          text="transfer funds"
          icon={<BiTransfer />}
        />
        <MenuItem
          link="/online-banking/transfer-history"
          text="transfer history"
          icon={<RiFolderHistoryLine />}
        />
      </Section>

      <Section title="account information">
        <MenuItem
          link="/online-banking/account-details"
          text="account details"
          icon={<RiAccountPinCircleLine />}
        />

        <MenuItem
          link="/online-banking/account-statement"
          text="account statement"
          icon={<IoNewspaperOutline />}
        />
      </Section>

      <Section title="logout" className=" xl:hidden">
        <Button className="text-white bg-red-600" onClick={signOut}>
          Log Out
        </Button>
      </Section>
    </div>
  );
};

export default Navigation;
