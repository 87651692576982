import React from "react";
import { FiTrash } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//actions
import { deleteTransAsync } from "../redux/transactions/trans-actions";

//utils
import addCommas from "../utils/addCommas";

//chakra ui
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";

const TransactionTable = ({ transactions, edit, label, name }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [trans, setTrans] = React.useState(null);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const dispatch = useDispatch();
  const deleting = useSelector((state) => state.transactions.deleting);

  const toast = useToast();

  const history = useHistory();

  const deleteTransaction = async () => {
    try {
      await dispatch(deleteTransAsync(trans));
      toast({
        title: "Transaction deleted.",
        description: "Transaction was deleted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      history.push("/admin");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Table variant="striped" colorScheme="green" className="text-sm">
        <TableCaption>
          {transactions.length === 0 && `${label ? label : "No Transactions"}`}
        </TableCaption>
        <Thead>
          <Tr>
            {name && <Th>Name</Th>}
            <Th>Date</Th>
            <Th>Description</Th>
            <Th>Withdrawals($)</Th>
            <Th>Deposits($)</Th>
            <Th>Balance($)</Th>
            <Th>Status</Th>
            {edit && <Th>Delete</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {transactions.length > 0 ? (
            <>
              {transactions.map((transaction) => {
                const amount = addCommas(Number(transaction.amount).toFixed(2));
                const isReceived = transaction.isReceived;

                return (
                  <Tr key={transaction.transId} className="capitalize">
                    {name && <Td>{transaction.name}</Td>}
                    <Td>{transaction.date}</Td>
                    <Td>{transaction.description}</Td>
                    <Td>{isReceived === false && amount}</Td>
                    <Td>{isReceived === true && amount}</Td>
                    <Td>{addCommas(Number(transaction.balance).toFixed(2))}</Td>
                    <Td>
                      {transaction.status === "successful" ? (
                        <Badge variant="solid" colorScheme="green">
                          Success
                        </Badge>
                      ) : (
                        <Badge variant="solid" colorScheme="red">
                          Failed
                        </Badge>
                      )}
                    </Td>

                    {edit && (
                      <Td>
                        <Button
                          className="p-0 bg-transparent"
                          onClick={() => {
                            setTrans(transaction);
                            setIsOpen(true);
                          }}
                        >
                          <FiTrash className="text-red-600" />
                        </Button>
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </>
          ) : null}
        </Tbody>
      </Table>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Transaction
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteTransaction} ml={3}>
                {deleting && <Spinner size="sm" className="mr-3" />}
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default TransactionTable;
