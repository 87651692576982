const customersActionTypes = {
  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_ERROR: "CREATE_CUSTOMER_ERROR",
  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  EDIT_CUSTOMER_INFO: "EDIT_CUSTOMER_INFO",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  DELETE_SUCCESS: "DELETE_SUCCESS",
};

export default customersActionTypes;
