import customersActionTypes from "./customers-action-types";

const INITIAL_STATE = {
  loading: false,
  isFetching: false,
  deleting: false,
  editing: false,
  customers: [],
};

const customersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case customersActionTypes.CREATE_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case customersActionTypes.CREATE_CUSTOMER_ERROR:
    case customersActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case customersActionTypes.GET_CUSTOMERS:
      return {
        ...state,
        isFetching: true,
      };

    case customersActionTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customers: action.payload,
      };

    case customersActionTypes.EDIT_CUSTOMER_INFO:
      return {
        ...state,
        editing: true,
      };

    case customersActionTypes.EDIT_SUCCESS:
      return {
        ...state,
        editing: false,
      };

    case customersActionTypes.DELETE_CUSTOMER:
      return {
        ...state,
        deleting: true,
      };

    case customersActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    default:
      return state;
  }
};

export default customersReducer;
