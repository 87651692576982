import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = ({ exact, path, Component }) => {
  const user = useSelector((state) => state.user.user);

  if (path === "/login" || path === "/register") {
    return (
      <Route
        exact={exact}
        path={path}
        render={() => {
          if (user && user.role === "customer") {
            return <Redirect to="/online-banking" />;
          } else if (user && user.role === "admin") {
            return <Redirect to="/admin" />;
          } else {
            return <Component />;
          }
        }}
      />
    );
  } else {
    if (path.includes("admin")) {
      return (
        <Route
          exact={exact}
          path={path}
          render={() => {
            if (user && user.role === "admin") {
              return <Component />;
            } else if (user && user.role === "customer") {
              return <Redirect to="/online-banking" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />
      );
    } else {
      return (
        <Route
          exact={exact}
          path={path}
          render={() => {
            if (user && user.role === "customer") {
              return <Component />;
            } else if (user && user.role === "admin") {
              return <Redirect to="/admin" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />
      );
    }
  }
};

export default AuthRoute;
