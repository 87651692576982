import React from "react";
import { Link } from "react-router-dom";

//components
import Layout from "../../../components/website/Layout";

//chakra-ui
import { Button } from "@chakra-ui/react";

const Fund = () => {
  return (
    <Layout>
      <div className="h-auto bg-gray-50">
        <div className="flex flex-col items-center justify-center h-48 space-y-12 text-white bg-center md:h-60 xl:items-start padding-h bg-fundlg-bg">
          <div className="w-full">
            <Button className="bg-primary">
              <Link to="/services">Back</Link>
            </Button>
          </div>

          <h1 className="text-3xl font-semibold capitalize md:text-6xl xl:text-7xl">
            The Royal Scotia Fund
          </h1>
        </div>

        <div className="py-20 space-y-5 padding-h">
          <p>
            Established in the Cayman Islands in 1991, the Royal Scotia Fund is
            an open-ended mutual fund company licensed and regulated under the
            Cayman Islands Mutual Fund Law.
          </p>

          <p>
            At Royal Scotia we do not impose entry or exit fees when our clients
            invest in The Royal Scotia Fund and there are no conversion fees to
            transfer between the funds of The Royal Scotia Fund. This industry
            leading practice provides our clients with enhanced tactical
            flexibility during times of change.
          </p>

          <p>
            Please note that The Royal Scotia Fund is available only to
            qualified investors and may not be available to residents of certain
            jurisdictions. For more information and to learn if you qualify for
            an investment in The Royal Scotia Fund please{" "}
            <Link to="/contact-us" className="text-primary">
              contact us
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Fund;
