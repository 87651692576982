import React from "react";
import { BiTransfer } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";

//utils
import { getBalance } from "../../utils/getBalance";
import addCommas from "../../utils/addCommas";

//actions
import { makeTransfer } from "../../redux/transactions/trans-actions";

//chakra ui
import { Divider, Button, useToast, Skeleton } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import PageTemplate from "../../components/PageTemplate";
import Input from "../../components/FormInput";
import Select from "../../components/Select";

const TransferFunds = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [currBalance, setBalance] = React.useState("");
  const [details, setDetails] = React.useState({
    receipAccNum: "",
    routingNum: "",
    amount: "",
    receipBankName: "",
    bankAddress: "",
    country: "",
  });

  const {
    user: { accountNum, id },
  } = useSelector((state) => state.user);

  const { creating } = useSelector((state) => state.transactions);

  React.useEffect(() => {
    const unsubscribe = getBalance(id, setBalance);

    return () => unsubscribe();
  }, [id]);

  const onChange = (e) => {
    const { name, value } = e.target;

    setDetails({
      ...details,
      [name]: value,
    });
  };

  const transferFunds = async () => {
    try {
      await dispatch(makeTransfer({ ...details, accountNum, id }));

      toast({
        title: "Funds Transfered.",
        description: "Funds have been transfered successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setDetails({
        receipAccNum: "",
        routingNum: "",
        amount: "",
      });
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setDetails({
        receipAccNum: "",
        routingNum: "",
        amount: "",
      });
    }
  };

  return (
    <Layout>
      <PageTemplate
        heading="transfer funds"
        subHeading="make a transfer"
        icon={<BiTransfer />}
      >
        <div className="space-y-3">
          <p className="capitalize">current balance</p>
          <h1 className="text-3xl text-gray-500">
            <Skeleton isLoaded={currBalance}>
              ${addCommas(Number(currBalance).toFixed(2))}
            </Skeleton>
          </h1>
        </div>

        <Divider />

        <form className="form">
          <Input
            label="sender's account number"
            type="number"
            name="senderAccNum"
            disabled={true}
            value={accountNum}
          />

          <Input
            label="receipient's account number"
            type="number"
            name="receipAccNum"
            onChange={onChange}
            value={details.receipAccNum}
          />

          <Input
            label="receipient's bank name"
            type="text"
            name="receipBankName"
            onChange={onChange}
            value={details.receipBankName}
          />

          <Input
            label="receipient's bank address"
            type="text"
            name="bankAddress"
            onChange={onChange}
            value={details.bankAddress}
          />

          <Select onChange={onChange} name="country" />

          <Input
            label="routing number"
            type="text"
            name="routingNum"
            onChange={onChange}
            value={details.routingNum}
          />

          <Input
            label="amount"
            type="number"
            name="amount"
            onChange={onChange}
            value={details.amount}
          />
        </form>

        <div className="flex justify-end">
          <Button
            isLoading={creating}
            className="text-white bg-primary"
            isDisabled={
              details.receipAccNum === "" ||
              details.amount === "" ||
              details.routingNum === ""
            }
            onClick={transferFunds}
          >
            Transfer Funds
          </Button>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default TransferFunds;
