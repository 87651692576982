import { Switch, Route } from "react-router-dom";

//components
import AuthRoute from "./components/AuthRoute";

//pages
import Login from "./pages/login";
// import Register from "./pages/register";

//admin
import AdminHome from "./pages/admin/home";
import NewCustomer from "./pages/admin/new-customer";
import ManageCustomers from "./pages/admin/manage-customers";
import AllTransactions from "./pages/admin/all-transactions";
import CreateTransactions from "./pages/admin/create-transactions";

//client
import CustomerHome from "./pages/customer/home";
import AccountDetails from "./pages/customer/account-details";
import AccountStatement from "./pages/customer/account-statement";
import TransferFunds from "./pages/customer/transfer-funds";
import TransferHistory from "./pages/customer/transfer-history";

//website
import HomePage from "./pages/website/home";
import Services from "./pages/website/services";
import About from "./pages/website/about";
import Contact from "./pages/website/contact";
//services
import Private from "./pages/website/services/private";
import Fund from "./pages/website/services/fund";
import Wealth from "./pages/website/services/wealth";
import Trust from "./pages/website/services/trust";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>

        <Route exact path="/services">
          <Services />
        </Route>

        <Route path="/about-us">
          <About />
        </Route>

        <Route path="/contact-us">
          <Contact />
        </Route>

        <Route path="/services/private-banking">
          <Private />
        </Route>

        <Route path="/services/corporate-services">
          <Trust />
        </Route>

        <Route path="/services/wealth-management">
          <Wealth />
        </Route>

        <Route path="/services/royal-scotia-fund">
          <Fund />
        </Route>

        <AuthRoute path="/login" Component={Login} />
        {/* <AuthRoute path="/register" Component={Register} /> */}

        {/* user routes */}
        <AuthRoute exact path="/online-banking" Component={CustomerHome} />
        <AuthRoute
          path="/online-banking/account-details"
          Component={AccountDetails}
        />
        <AuthRoute
          path="/online-banking/account-statement"
          Component={AccountStatement}
        />
        <AuthRoute
          path="/online-banking/transfer-funds"
          Component={TransferFunds}
        />
        <AuthRoute
          path="/online-banking/transfer-history"
          Component={TransferHistory}
        />

        {/* admin routes */}
        <AuthRoute exact path="/admin" Component={AdminHome} />
        <AuthRoute path="/admin/new-customer" Component={NewCustomer} />
        <AuthRoute path="/admin/manage-customers" Component={ManageCustomers} />
        <AuthRoute path="/admin/all-transactions" Component={AllTransactions} />
        <AuthRoute
          path="/admin/create-transactions"
          Component={CreateTransactions}
        />
      </Switch>
    </>
  );
}

export default App;
