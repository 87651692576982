import React from "react";

const NavSection = ({ title, children, className }) => {
  return (
    <div className={`py-10 space-y-5 ${className}`}>
      <h1 className="text-xs tracking-wider text-gray-400 capitalize">
        {title}
      </h1>
      <ul className="space-y-3">{children}</ul>
    </div>
  );
};

export default NavSection;
