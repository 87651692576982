import React from "react";
import { FiUserPlus, FiHome, FiUsers, FiRepeat } from "react-icons/fi";
import { RiExchangeDollarLine } from "react-icons/ri";
import { useDispatch } from "react-redux";

//actions
import { logOutAsync } from "../../redux/auth/auth-actions";

//chakra ui
import { Button } from "@chakra-ui/react";

//components
import Section from "../NavSection";
import MenuItem from "../MenuItem";

const Navigation = () => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logOutAsync());
  };

  return (
    <div className="navigation">
      <Section title="admin account">
        <MenuItem link="/admin" text="dashboard" icon={<FiHome />} />
      </Section>

      <Section title="customer management">
        <MenuItem
          link="/admin/new-customer"
          text="new customer"
          icon={<FiUserPlus />}
        />
        <MenuItem
          link="/admin/manage-customers"
          text="manage customers"
          icon={<FiUsers />}
        />
      </Section>

      <Section title="transaction management">
        <MenuItem
          link="/admin/create-transactions"
          text="create transactions"
          icon={<FiRepeat />}
        />
        <MenuItem
          link="/admin/all-transactions"
          text="all transactions"
          icon={<RiExchangeDollarLine />}
        />
      </Section>

      <Section title="logout" className=" xl:hidden">
        <Button className="text-white bg-red-600" onClick={signOut}>
          Log Out
        </Button>
      </Section>
    </div>
  );
};

export default Navigation;
