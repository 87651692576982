import transActionTypes from "./trans-action-types";

const INITIAL_STATE = {
  transactions: [],
  creating: false,
  fetching: false,
  deleting: false,
};

const transReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transActionTypes.CREATE_TRANS:
      return {
        ...state,
        creating: true,
      };

    case transActionTypes.CREATE_TRANS_ERROR:
    case transActionTypes.CREATE_TRANS_SUCCESS:
      return {
        ...state,
        creating: false,
      };

    case transActionTypes.GET_ALL_TRANS:
    case transActionTypes.GET_CUSTOMER_TRANS:
      return {
        ...state,
        fetching: true,
      };

    case transActionTypes.GET_TRANS_SUCCESS:
      return {
        ...state,
        fetching: false,
        transactions: action.payload,
      };

    case transActionTypes.DELETE_TRANS:
      return {
        ...state,
        deleting: true,
      };

    case transActionTypes.DELETE_TRANS_ERROR:
    case transActionTypes.DELETE_TRANS_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    default:
      return state;
  }
};

export default transReducer;
