import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiUsers, FiHome } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import { CgArrowLongUpC, CgArrowLongDownC } from "react-icons/cg";

//utils
import addCommas from "../../utils/addCommas";
// import {
//   balanceChartConfigs,
//   sentChartConfigs,
//   receivedChartConfigs,
// } from "../../utils/chartConfigs";

//fusion chart
// import ReactFC from "react-fusioncharts";
// import FusionCharts from "fusioncharts";
// import Column2D from "fusioncharts/fusioncharts.charts";
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

//actions
import { getCustomersAsync } from "../../redux/customers/customers-actions";
import { getAllTransAsync } from "../../redux/transactions/trans-actions";

//chakra ui
import { useToast, StatNumber, StatArrow, Skeleton } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import TransactionsTable from "../../components/TransactionsTable";
import PageTemplate from "../../components/PageTemplate";
import CustomStat from "../../components/Stat";

// ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const AdminHome = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const customers = useSelector((state) => state.customers.customers);
  const transactions = useSelector((state) => state.transactions.transactions);
  const fetching = useSelector((state) => state.transactions.fetching);
  const loading = useSelector((state) => state.customers.isFetching);

  const lastTenTransactions = transactions.filter((_, idx) => idx < 10);

  const sent = transactions.filter(
    (transaction) =>
      transaction.isReceived === false && transaction.status === "successful"
  );

  const received = transactions.filter(
    (transaction) =>
      transaction.isReceived === true && transaction.status === "successful"
  );

  const totalBalance = customers.reduce((acc, { balance }) => {
    return acc + Number(balance);
  }, 0);

  const sentTotal = sent.reduce((acc, { amount }) => {
    return acc + Number(amount);
  }, 0);

  const receivedTotal = received.reduce((acc, { amount }) => {
    return acc + Number(amount);
  }, 0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCustomersAsync());
        await dispatch(getAllTransAsync());
      } catch (error) {
        return toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [dispatch, toast]);

  return (
    <Layout>
      <PageTemplate
        heading="Admin Dashboard"
        subHeading="Customer Statistics"
        icon={<FiHome />}
      >
        <div className="grid gap-3 md:grid-cols-2">
          <Card>
            <FiUsers className="icon" />
            <CustomStat
              label="total customers"
              number={
                <Skeleton isLoaded={!loading}>
                  <StatNumber> {customers.length} customer(s)</StatNumber>
                </Skeleton>
              }
            />
          </Card>

          <Card>
            <BiWallet className="icon" />
            <CustomStat
              label="total balance"
              number={
                <Skeleton isLoaded={!loading}>
                  <StatNumber>
                    {" "}
                    ${addCommas(totalBalance.toFixed(2))}
                  </StatNumber>
                </Skeleton>
              }
            />
          </Card>

          <Card>
            <CgArrowLongUpC className="icon" />
            <CustomStat
              label="total sent"
              number={
                <Skeleton isLoaded={!fetching}>
                  <StatNumber>${addCommas(sentTotal.toFixed(2))}</StatNumber>
                </Skeleton>
              }
              arrow={<StatArrow type="decrease" />}
            />
          </Card>

          <Card>
            <CgArrowLongDownC className="icon" />
            <CustomStat
              label="total received"
              number={
                <Skeleton isLoaded={!fetching}>
                  <StatNumber>
                    ${addCommas(receivedTotal.toFixed(2))}
                  </StatNumber>
                </Skeleton>
              }
              arrow={<StatArrow type="increase" />}
            />
          </Card>
        </div>

        {/* <div className="space-y-5">
          <h3 className="subHeading">Statistics Chart</h3>
          <div className="flex flex-col items-center space-y-5 md:flex-row md:justify-center lg:space-x-5 md:space-y-0">
            <ReactFC {...balanceChartConfigs(totalBalance)} />
            <ReactFC {...sentChartConfigs(sentTotal)} />
            <ReactFC {...receivedChartConfigs(receivedTotal)} />
          </div>
        </div> */}

        <div className="space-y-5">
          <h3 className="subHeading">Recent Transactions</h3>

          <Skeleton isLoaded={!fetching}>
            <TransactionsTable transactions={lastTenTransactions} name />
          </Skeleton>
        </div>
      </PageTemplate>
    </Layout>
  );
};

export default AdminHome;
