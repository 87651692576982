import React from "react";
import { Link } from "react-router-dom";

const MenuLink = ({ link, text }) => {
  return (
    <li
      className={`hover:text-primary ${
        window.location.pathname === link && "text-primary"
      }`}
    >
      <Link to={link}>{text}</Link>
    </li>
  );
};

export default MenuLink;
