const transActionTypes = {
  CREATE_TRANS: " CREATE_TRANS",
  CREATE_TRANS_SUCCESS: "CREATE_TRANS_SUCCESS",
  CREATE_TRANS_ERROR: "CREATE_TRANS_ERROR",
  GET_ALL_TRANS: "GET_ALL_TRANS",
  GET_CUSTOMER_TRANS: "GET_CUSTOMER_TRANS",
  GET_TRANS_SUCCESS: "GET_TRANS_SUCCESS",
  DELETE_TRANS: "DELETE_TRANS",
  DELETE_TRANS_SUCCESS: "DELETE_TRANS_SUCCESS",
  DELETE_TRANS_ERROR: "DELETE_TRANS_ERROR",
};

export default transActionTypes;
