import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoNewspaperOutline } from "react-icons/io5";

//actions
import { getCustomerTransAsync } from "../../redux/transactions/trans-actions";

//chakra ui
import { Skeleton, useToast } from "@chakra-ui/react";

//components
import Layout from "../../components/Layout";
import PageTemplate from "../../components/PageTemplate";
import TransactionsTable from "../../components/TransactionsTable";

const AccountStatement = () => {
  const {
    user: { id },
  } = useSelector((state) => state.user);
  const { transactions, fetching } = useSelector((state) => state.transactions);

  const dispatch = useDispatch();

  const toast = useToast();

  React.useEffect(() => {
    const getTransactions = async () => {
      try {
        await dispatch(getCustomerTransAsync(id));
      } catch (error) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    getTransactions();
  }, [dispatch, id, toast]);

  return (
    <Layout>
      <PageTemplate
        heading="account statement"
        subHeading="all account transactions"
        icon={<IoNewspaperOutline />}
      >
        <Skeleton isLoaded={!fetching}>
          <TransactionsTable transactions={transactions} />
        </Skeleton>
      </PageTemplate>
    </Layout>
  );
};

export default AccountStatement;
